<template>
  <div class="d-flex flex-column flex-root">
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <KTHeader :portal="true"></KTHeader>
        <div id="kt_content" class="d-flex flex-column flex-column-fluid">
          <router-view />
        </div>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>

    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-right'"
      :transitionName="'slideFromBottom'"
      @status="cookieStatus"
      @clickedAccept="cookieClickedAccept"
      @clickedDecline="cookieClickedDecline"
    >
      <div slot="message" class="lead">
        This website uses cookies.<br />
        See our
        <router-link to="/privacy" target="_blank">Privacy Policy</router-link>
        and
        <a href="https://www.cookiesandyou.com/" target="_blank"
          >Cookie Policy</a
        >
        to learn more.
      </div>
    </vue-cookie-accept-decline>

    <div id="customCss"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import i18n from "@/core/plugins/vue-i18n";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "PortalLayout",
  components: {
    KTHeader,
    KTHeaderMobile,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // Simulate the delay page loading
    // Remove page loader after some time
    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 1000);

    setTimeout(() => {
      let customCss = document.getElementById("customCss");
      if (this.getOrganization.data) {
        customCss.innerHTML =
          "<style>.bg-primary, .label-primary { background-color: " +
          this.getOrganization.data.primaryColor +
          "!important; } .bg-warning { background-color: " +
          this.getOrganization.data.secondaryColor +
          "!important;} .btn-primary { background-color: " +
          this.getOrganization.data.primaryColor +
          "!important; border: 0; color: #fff!important; } .btn-primary:hover { background-color: " +
          this.getOrganization.data.secondaryColor +
          "!important; border: 0; color: #fff!important; }  .text-primary { color: " +
          this.getOrganization.data.secondaryColor +
          "!important;} .svg-icon-primary { color: " +
          this.getOrganization.data.secondaryColor +
          "!important;} .bg-secondary { background-color: " +
          this.getOrganization.data.secondaryColor +
          "!important; color: #fff !important;} .menu-text { font-weight: 600;} .nav-link.active{ color: " +
          this.getOrganization.data.primaryColor +
          "!important;} .border-bottom-primary { border-bottom-color: " +
          this.getOrganization.data.primaryColor +
          "!important;} .btn-default { background-color: #efefef!important;} .rectangle{background-color: " +
          this.getOrganization.data.primaryColor +
          "!important;} .card-border{border-color: " +
          this.getOrganization.data.secondaryColor +
          "!important;} .btn.btn-outline-primary{ border-color: " +
          this.getOrganization.data.primaryColor +
          "!important; color: " +
          this.getOrganization.data.primaryColor +
          "!important;} .btn.btn-outline-primary:hover:not(.btn-text), .btn.btn-outline-primary:focus:not(.btn-text), .btn.btn-outline-primary.focus:not(.btn-text){ border-color: " +
          this.getOrganization.data.secondaryColor +
          "!important; color: " +
          this.getOrganization.data.secondaryColor +
          "!important;background-color: transparent !important; } .link{color: " +
          this.getOrganization.data.secondaryColor +
          "!important; }</style>";
        i18n.locale = i18nService.getActiveLanguage();
      }
    }, 100);
  },
  watch: {
    getOrganization: {
      handler() {
        this.setCss();
      },
      immediate: true,
    },
  },
  methods: {
    setCss() {
      if (this.getOrganization.data != null) {
        let customCss = document.getElementById("customCss");
        if (customCss != null) {
          customCss.innerHTML =
            "<style>.bg-primary, .label-primary { background-color: " +
            this.getOrganization.data.primaryColor +
            "!important; } .bg-warning { background-color: " +
            this.getOrganization.data.secondaryColor +
            "!important;} .btn-primary { background-color: " +
            this.getOrganization.data.primaryColor +
            "!important; border: 0; color: #fff!important; } .btn-primary:hover { background-color: " +
            this.getOrganization.data.secondaryColor +
            "!important; border: 0; color: #fff!important; }  .text-primary { color: " +
            this.getOrganization.data.secondaryColor +
            "!important;} .svg-icon-primary { color: " +
            this.getOrganization.data.secondaryColor +
            "!important;} .bg-secondary { background-color: " +
            this.getOrganization.data.secondaryColor +
            "!important; color: #fff !important;} .menu-text { font-weight: 600;} .nav-link.active{ color: " +
            this.getOrganization.data.primaryColor +
            "!important;} .border-bottom-primary { border-bottom-color: " +
            this.getOrganization.data.primaryColor +
            "!important;} .btn-default { background-color: #efefef!important;} .rectangle{background-color: " +
            this.getOrganization.data.primaryColor +
            "!important;} .card-border{border-color: " +
            this.getOrganization.data.secondaryColor +
            "!important;} .btn.btn-outline-primary{ border-color: " +
            this.getOrganization.data.primaryColor +
            "!important; color: " +
            this.getOrganization.data.primaryColor +
            "!important;} .btn.btn-outline-primary:hover:not(.btn-text), .btn.btn-outline-primary:focus:not(.btn-text), .btn.btn-outline-primary.focus:not(.btn-text){ border-color: " +
            this.getOrganization.data.secondaryColor +
            "!important; color: " +
            this.getOrganization.data.secondaryColor +
            "!important;background-color: transparent !important; } .link{color: " +
            this.getOrganization.data.secondaryColor +
            "!important; }</style>";
        }
        i18n.locale = i18nService.getActiveLanguage();
      }
    },
    cookieStatus(status) {
      this.status = status;
    },
    cookieClickedAccept() {
      this.status = "accept";
    },
    cookieClickedDecline() {
      this.status = "decline";
    },
    removeCookie() {
      this.$refs.myPanel1.removeCookie();
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "layoutConfig", "getOrganization"]),

    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>
<style scoped>
.aside-fixed.aside-minimize .wrapper {
  padding-left: 0 !important;
}
.header-fixed.aside-minimize-hover .header,
.header-fixed.aside-minimize .header {
  left: 0 !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 65px !important;
}

@media (max-width: 991.98px) {
  .header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 55px !important;
  }
}
</style>
